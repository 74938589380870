<!--
File: HomoSectionsList.vue
Description: show list of road sections entered in the DB.
-->
<template>
  <div class="md-layout">
    <md-card v-show="!showHomoSections">
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-15">
            <BaseDropdown id='yearFilter' :label="$t('budget.select_year')" v-model="selectedYear"
              :items="yearsInHSList()" :displayField="'year'" :valueField="'year'" />
          </div>
          <div class="md-layout-item btn-row md-small-size-100">
            <md-button class="md-success" @click="generateHS"> {{ $t('buttons.generate') }} </md-button>
          </div>
        </div>
      </md-card-header>

      <md-card-content>
        <md-progress-spinner v-if="showSpinner" class="md-progress-spinner" :md-diameter="70" md-mode="indeterminate" />
        <md-table class="paginated-table table-striped table-hover" :value="tableData" :md-sort.sync="currentSort"
          :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>
          <md-table-empty-state :md-label="$t('label.no_data')"
            :md-description="$t('messages.select_another_criteria')" />
          <md-table-row slot="md-table-row" slot-scope="{item}">
            <md-table-cell :md-label="$t('label.from_year')" md-sort-by="input_year_from">
              {{ item.input_year_from }}
            </md-table-cell>
            <md-table-cell :md-label="$t('label.to_year')" md-sort-by="input_year_to">
              {{ item.input_year_to }}
            </md-table-cell>
            <md-table-cell :md-label="$t('stdCols.range')" md-sort-by="input_condition_index_threshold" md-numeric>
              {{ item.input_condition_index_threshold | numFormat(0) }}
            </md-table-cell>
            <md-table-cell :md-label="$t('label.min_hs_length')" md-sort-by="input_min_hs_length" md-numeric>
              {{ item.input_min_hs_length | numFormat(0) }}
            </md-table-cell>
            <md-table-cell :md-label="$t('road_network.section_count')" md-sort-by="hs_count" md-numeric>
              {{ item.hs_count | numFormat(0) }}
            </md-table-cell>
            <md-table-cell :md-label="$t('stdCols.created_at')" md-sort-by="generated_on">
              {{ item.generated_on | dateFormat(true) }}
            </md-table-cell>
            <md-table-cell :md-label="$t('users.role_user')" md-sort-by="generated_by_user_name">
              {{ item.generated_by_user_name }}
            </md-table-cell>
            <md-table-cell :md-label="$t('budget.approved')" md-sort-by="approved_on">
              {{ item.approved_on | dateFormat }}
            </md-table-cell>
            <md-table-cell :md-label="$t('tables.actions')">
              <div class="cell-actions">
                <md-button v-if="item.hs_count > 0" class="md-just-icon md-success md-simple"
                  :title="$t('buttons.details')" @click="showDetails(item.id)">
                  <md-icon>preview</md-icon>
                </md-button>
                <md-button v-if="amIAdmin" class="md-just-icon md-danger md-simple" :title="$t('buttons.delete')"
                  @click.stop.prevent="handleDelete(item)">
                  <md-icon>delete</md-icon>
                </md-button>
                <md-button v-if="item.hs_count > 0"
                  :class="['md-raised', 'md-sm', item.approved_by_user_id ? 'md-primary' : 'md-success']"
                  @click="toggleListApproval(item, !item.approved_by_user_id)">
                  {{ item.approved_by_user_id ? $t('buttons.revoke') : $t('buttons.approve') }}
                </md-button>
                <!--template v-if="!item.approved_by_user_id">
                  <md-button class="md-success md-raised md-sm" @click="approveHS(item)">
                    {{ $t('buttons.approve') }}
                  </md-button>
                </template>
                <template v-else>
                  <md-button class="md-raised md-sm md-primary" @click="revokeHS(item)">
                    {{ $t('buttons.revoke') }}
                  </md-button>
                </template-->
              </div>
            </md-table-cell>
          </md-table-row>
        </md-table>
        <div class="footer-table md-table">
          <table>
            <tfoot>
              <tr>
                <th v-for="item in footerTable" :key="item" class="md-table-head">
                  <div class="md-table-head-container md-ripple md-disabled">
                    <div class="md-table-head-label">{{ item }}</div>
                  </div>
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </md-card-content>
    </md-card>

    <div v-show="showHomoSections" :style="{ width: '100%', height: '100%' }">
      <HomoSections :hsId="selectedHSId" @close="showHomoSections = false" />
    </div>

    <md-dialog :md-active.sync="showInputParams" :md-click-outside-to-close="false">
      <md-dialog-title>{{ $t('label.homo_sections') }}
        <md-button class='md-simple md-just-icon md-round modal-default-button' @click='showInputParams = false'>
          <md-icon>clear</md-icon>
        </md-button>
      </md-dialog-title>
      <HomoSectionInputForm @input='showGeneratedHS' @close="showInputParams = false" />
    </md-dialog>
  </div>
</template>
<script>
  import Swal from 'sweetalert2'
  import BaseDropdown from '@/pages/Dropdowns/BaseDropdown.vue'
  import HomoSections from './HomoSections.vue'
  import HomoSectionInputForm from './HomoSectionInputForm.vue'
  import { mapState, mapGetters } from 'vuex'
  import { customSortMixin } from '@/mixins/customSortMixin'
  import { numFormat } from "@/store/refdata/format_helpers"

  export default {
    mixins: [customSortMixin],
    name: 'homogeneous-sections-list',
    data() {
      return {
        selectedYear: null,
        selectedHSId: null,
        showHomoSections: false,
        showInputParams: false,
        showSpinner: false,
        propsToSearch: ['road', 'section_description', 'generated_by_user_name'],
        currentSort: 'generated_on',
        currentSortOrder: 'desc',
      }
    },
    components: {
      BaseDropdown,
      HomoSections,
      HomoSectionInputForm
    },
    mounted() {
      this.toggleSpinner(true)
      this.$store.dispatch('RESET_HS_LIST')
      this.reloadData()
    },

    methods: {
      toggleSpinner(state) {
        this.showSpinner = state
      },

      reloadData() {
        this.toggleSpinner(true)
        this.searchQuery = ''
        this.$store.dispatch('LOAD_HS_LIST', {}).then(() => {
          this.toggleSpinner(false)
        })
      },

      showDetails(id) {
        this.selectedHSId = id
        //console.log('showDetails', this.selectedHSId)
        this.showHomoSections = true
      },

      generateHS() {
        this.showInputParams = true
      },

      showGeneratedHS(id) {
        //console.log('showGeneratedHS', id)
        this.showInputParams = false
        this.reloadData()
        this.showDetails(id)
      },

      toggleListApproval(item, status) {
        // Check wheter there is already approved list
        const approvedHSListExists = this.approvedYearsInHSList.find((element) => element.year === item.year)
        if (status && approvedHSListExists) {
          Swal.fire(this.$t('messages.approved_hs_list_exists_title'), this.$t('messages.approved_hs_list_exists'), 'warning')
          return
        }

        this.toggleSpinner(true)
        this.$store.dispatch(status ? 'APPROVE_HS_LIST' : 'CANCEL_HS_LIST', item.homogeneous_section_list_id).then(() => {
          this.reloadData()
          Swal.fire(status ? this.$t('budget.approved') : this.$t('messages.revoked'), name, 'success')
          this.toggleSpinner(false)
        })
      },

      handleDelete(item) {
        this.toggleSpinner(true)
        const alert = {
          title: this.$t('modal.are_you_sure'),
          html: `${this.$t('modal.you_want_delete', { name: item.year })}<br><br>
            ${this.$t('modal.the_operation_cannot_be_canceled')}`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t('modal.yes_delete'),
        }
        Swal.fire(alert).then((result) => {
          if (result.value) {
            this.$store.dispatch('DEL_HS_LIST', item.homogeneous_section_list_id).then(
              () => {
                this.reloadData()
                Swal.fire(this.$t('modal.deleted'), this.$t('modal.you_deleted', { name: item.year }), 'success')
                this.toggleSpinner(false)
              },
              (err) => {
                Swal.fire(this.$t('messages.error'), err, 'error')
              }
            )
          }
        })
      },
    },

    computed: {
      ...mapState({
        hsList: (state) => state.HomoSections.list,
        homoSections: (state) => state.HomoSections.hsList,
      }),
      ...mapGetters(['amIAdmin', 'yearsInHSList', 'hsListByYear', 'approvedYearsInHSList']),
      total() {
        return this.tableData.length
      },
      tableData() {
        return this.customSort(this.hsListByYear(this.selectedYear))
      },
      footerTable() {
        return [
          this.$t('label.total'),
          `${numFormat(this.total, 0)} ${this.$t('label.entries')}`
        ]
      }
    },

    watch: {
    }
  }
</script>
<style lang="scss" scoped>
.md-card {
  margin: 0px 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.cell-actions {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: flex-end;

  .md-button {
    margin: 3px 0;
    min-width: 80px;
  }

  .md-just-icon {
    margin: 3px 0;
    min-width: 10px;

  }
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}

.homo-sections {
  height: 100% !important;
  width: 100% !important;
  display: flex;
  flex-direction: column;
}

.parent-container {
  height: 100%;
  width: 100%;
}
</style>