<!--
File: HomoSections.vue
Description: show list of road sections entered in the DB.
-->
<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-20">
          <md-button class="md-primary md-simple" @click='onModalClose'>
            <md-icon>arrow_back</md-icon>{{ $t('Back to the list') }}
          </md-button>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-25">
          <RegionsDropdown :label="$t('label.select_region')" v-model="selectedRegion" data-vv-name="selectedRegion"
            :initial_value="selectedRegion" />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-30">
          <BaseDropdown id='yearFilter' :label="$t('label.select_road')" v-model="selectedRoad" :items="roadsList"
            :displayField="'name'" :valueField="'id'" />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <md-field>
            <md-input type="search" class="mb-3" clearable style="width: 200px" :placeholder="$t('label.search_records')"
              v-model="searchQuery" />
          </md-field>
        </div>
      </div>
      <md-button class='md-simple md-just-icon md-round modal-default-button' @click='onModalClose'>
        <md-icon>clear</md-icon>
      </md-button>
    </md-card-header>

    <md-card-content>
      <md-progress-spinner v-if="showSpinner" class="md-progress-spinner" :md-diameter="70" md-mode="indeterminate" />
      <md-table class="paginated-table table-striped table-hover" :value="queriedData" md-fixed-header>
        <md-table-empty-state :md-label="$t('label.no_data')" :md-description="$t('messages.select_another_criteria')" />
        <md-table-row slot="md-table-row" slot-scope="{item}">
          <md-table-cell :md-label="$t('road_network.road')">
            {{ item.road_key }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.section_description')">
            {{ item.section_description }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.hs_id')" md-numeric>
            {{ item.homogeneous_section_id }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.start_km')" md-numeric>
            {{ item.start_distance / 1000 | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.end_km')" md-numeric>
            {{ item.end_distance / 1000 | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('condition.condition_index')" md-numeric>
            {{ item.condition_index }}
          </md-table-cell>
        </md-table-row>
      </md-table>
      <div class="footer-table md-table">
  <table>
    <tfoot>
      <tr>
        <th v-for="item in footerTable" :key="item" class="md-table-head">
          <div class="md-table-head-container md-ripple md-disabled">
            <div class="md-table-head-label">{{ item }}</div>
          </div>
        </th>
      </tr>
    </tfoot>
  </table>
</div>
    </md-card-content>
  </md-card>
</template>
<script>
  import Fuse from 'fuse.js'
  import Swal from 'sweetalert2'
  import RegionsDropdown from '@/pages/Dropdowns/RegionsDropdown.vue'
  import BaseDropdown from '@/pages/Dropdowns/BaseDropdown.vue'
  import { customSortMixin } from '@/mixins/customSortMixin'
  import { numFormat } from "@/store/refdata/format_helpers"
  import { mapState, mapGetters } from 'vuex'
  import { onModalClose } from '@/mixins/onCloseMixin'

  export default {
    name: 'homogeneous-sections',
    mixins: [customSortMixin],
    props: {
      hsId: { default: null, type: Number },
    },
    data() {
      return {
        selectedRegion: null,
        selectedRoad: null,
        showSpinner: false,
        searchQuery: '',
        propsToSearch: ['road_key', 'section_description'],
        searchedData: [],
        fuseSearch: null,
        currentSort: 'road_key',
        currentSortOrder: 'asc',
      }
    },
    components: {
      RegionsDropdown,
      BaseDropdown
    },
    mounted() {
      this.toggleSpinner(true)
      this.$store.dispatch('RESET_HS')
      //this.reloadData()
    },

    methods: {
      onModalClose,
      toggleSpinner(state) {
        this.showSpinner = state
      },
      reloadData(searchQuery) {
        this.toggleSpinner(true)
        this.searchQuery = ''
        this.$store.dispatch('LOAD_ALL_HS', this.hsId).then(() => {
          // Fuse search initialization.
          this.fuseSearch = new Fuse(this.hsList, { keys: this.propsToSearch, threshold: 0.3 })
          if (searchQuery) this.searchQuery = searchQuery
          this.toggleSpinner(false)
        })
      }
    },

    computed: {
      ...mapState({
        hsList: (state) => state.HomoSections.hsList,
      }),
      ...mapGetters(['amIAdmin', 'homoSectionsFiltered', 'roadsInList', 'homoSectionSummary']),
      queriedData() {
        const result = !this.searchQuery ? this.tableData : this.searchedData //? this.searchedData : []
        return result
      },
      total() {
        return this.searchQuery ? this.searchedData.length : this.tableData.length
      },
      tableData() {
        return this.customSort(this.homoSectionsFiltered(this.selectedRegion, this.selectedRoad), 'start_distance')
      },
      roadsList() {
        return this.roadsInList(this.selectedRegion)
      },
      summary() {
        return this.homoSectionSummary(this.selectedRegion, this.selectedRoad)
      },
      footerTable() {
       return [
       this.$t('route.summary'),
 //         `Roads: ${numFormat(this.summary.roadsCount, 0)}`,
          `Sections: ${numFormat(this.summary.sectionsCount, 0)}`,
          `Length: ${numFormat(this.summary.sectionsLength, 3)}`
        ];
      }
    },

    watch: {
      searchQuery(value) {
        if (this.tableData.length == 0) {
          this.searchedData = []
          return
        }
        this.searchedData = (value === '' || !this.fuseSearch) ? this.tableData : this.fuseSearch.search(this.searchQuery)
      },
      hsId() {
        console.log('hsId changed')
        this.selectedRegion = null
        this.selectedRoad = null
        this.reloadData()
      }
    }
  }
</script>
<style lang="scss" scoped>
.md-card {
  margin: 0px 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}
</style>