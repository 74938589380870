<!--
File: TreatmentsCosts.vue
Description: component of the Preparatory.vue almost same as WorkList component in TitleList.
-->
<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-20 md-size-15">
          <BaseDropdown :label="$t('budget.select_year')" v-model="selectedWorklist" :items="savedWorksList"
            :displayField="'year'" :valueField="'work_list_id'" @input="loadTreatmentsList" />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <RegionsDropdown :label="$t('label.select_region')" v-model="selectedRegion" :initial_value="selectedRegion"
            @input="loadTreatmentsList" />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-25">
          <BaseDropdown :label="$t('label.select_road')" v-model="selectedRoad" :items="roadsList"
            :displayField="'name'" :valueField="'id'" />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-15">
          <AmountsDropdown :label="$t('label.show_as')" v-model="showAmounts" />
        </div>
        <div class="md-layout-item btn-row md-small-size-100">
          <md-button class="md-success" @click="checkAndGenerate"> {{ $t('buttons.generate') }} </md-button>
        </div>
      </div>
    </md-card-header>

    <md-card-content>
      <md-progress-spinner v-show="showSpinner" class="md-progress-spinner" :md-diameter="70" md-mode="indeterminate" />
      <md-table class='"paginated-table table-striped table-hover' :value="tableData" :md-sort.sync="currentSort"
        :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>
        <md-table-empty-state :md-label="$t('label.no_data')"
          :md-description="$t('messages.select_another_criteria')" />
        <md-table-row slot="md-table-row" slot-scope="{item}">
          <md-table-cell :md-label="$t('road_network.road')" md-sort-by="road_description">
            {{ item.road_description }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.section_description')" md-sort-by="section_description">
            {{ item.section_description }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.start_km')" md-sort-by="start_m" md-numeric>
            {{ item.start_m / 1000 | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.end_km')" md-sort-by="end_m" md-numeric>
            {{ item.end_m / 1000 | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.quantity')" md-sort-by="units" md-numeric>
            {{ item.units | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.units')" md-sort-by="units" md-numeric>
            {{ item.unit_description }}
          </md-table-cell>
          <md-table-cell :md-label="$t('treatment.treatment_desc')" md-sort-by="treatment_type_description">
            {{ item.treatment_type_description }}
          </md-table-cell>
          <md-table-cell :md-label="$t('condition.priority_index')" md-sort-by="priority_index" md-numeric>
            {{ item.priority_index }}
          </md-table-cell>
          <md-table-cell :md-label="costLabel" md-sort-by="cost" md-numeric>
            {{ item.cost / showAmounts | numFormat }}
          </md-table-cell>
        </md-table-row>
      </md-table>
      <div class="footer-table md-table">
        <table>
          <tfoot>
            <tr>
              <th v-for="item in footerTable" :key="item" class="md-table-head">
                <div class="md-table-head-container md-ripple md-disabled">
                  <div class="md-table-head-label">{{ item }}</div>
                </div>
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    </md-card-content>
  </md-card>
</template>
<script>
import Swal from 'sweetalert2'
import RegionsDropdown from '@/pages/Dropdowns/RegionsDropdown.vue'
import BaseDropdown from '@/pages/Dropdowns/BaseDropdown.vue'
import AmountsDropdown from '@/pages/Components/AmountsDropdown.vue'
import { mapState, mapGetters } from 'vuex'
import { customSortMixin } from '@/mixins/customSortMixin'
import { numFormat } from "@/store/refdata/format_helpers"

export default {
  mixins: [customSortMixin],
  name: 'treatments-costs-form',
  data() {
    return {
      showSpinner: true,
      selectedWorklist: null,
      selectedRegion: null,
      selectedRoad: null,

      showAmounts: 1000000,
      costLabel: "Cost",
      currentSort: 'priority_index',
      currentSortOrder: 'desc',
    }
  },

  components: {
    RegionsDropdown,
    BaseDropdown,
    AmountsDropdown
  },

  mounted() {
    this.costLabel = `${this.$t('condition.cost')} ${this.$t('label.soms')}`
    this.loadWorksLists()
  },

  methods: {
    toggleSpinner(state) {
      this.showSpinner = state
    },

    loadWorksLists() {
      this.toggleSpinner(true)
      this.$store.dispatch('LOAD_WORKS_LISTS', {}).then(() => {
        this.selectedWorklist = this.savedWorksList.at(this.savedWorksList.length - 1).work_list_id
        //this.loadTreatmentsList()
      })
      this.toggleSpinner(false)
    },

    loadTreatmentsList() {
      this.toggleSpinner(true)
      const params = {
        work_list_id: this.selectedWorklist,
        region_id: this.selectedRegion,
      }
      this.$store.dispatch('LOAD_ALL_WORKS', params).then(() => {
        this.toggleSpinner(false)
      })
    },

    async checkAndGenerate() {
      try {
        //await this.$store.dispatch('LOAD_HS_LIST', { is_approved: 1 })

        const yearsList = this.approvedYearsInHSList
        if (yearsList.length == 0) {
          await Swal.fire(this.$t('messages.error'), this.$t('messages.no_approved_homosections'), 'error')
          return
        }

        // Create list of years as an object for user selection
        const inputOptions = yearsList.reduce((acc, item) => {
          acc[item.year] = item.year
          return acc
        }, {})

        const { value: theYear } = await Swal.fire({
          title: this.$t('messages.treatment_generation_title'),
          input: "select",
          inputOptions: inputOptions,
          inputPlaceholder: this.$t('budget.select_year'),
          showCancelButton: true,
        })

        // Exit if user selects cancel
        if (!theYear) return

        // Check if already generated for this year
        const existingWork = this.savedWorksList.find(el => el.year == theYear)
        if (!existingWork) {
          this.generateWorks(theYear)
          return
        }

        // Treatment list for the selected year exist, ask user whether to delete it
        const { value: confirmDeletion } = await Swal.fire({
          title: this.$t('messages.treatment_exists_title'),
          icon: 'warning',
          html: this.$t('messages.treatment_exists'),
          showCancelButton: true,
          confirmButtonText: this.$t('upload.yes'),
          allowEscapeKey: false
        })

        // Exit if user selects cancel
        if (!confirmDeletion) return

        this.showSpinner = true
        try {
          await this.$store.dispatch('DEL_WORK_LIST', existingWork.work_list_id)
          this.generateWorks(theYear)
        } catch (error) {
          console.error('Error while deleting work list:', error)
        }
      } catch (error) {
        console.error('Error in checkAndGenerate:', error)
      }
    },

    generateWorks(year) {
      this.$store.dispatch('GENERATE_WORKS', year).then((res) => {
        this.loadWorksLists()
        this.selectedWorklist = res.work_list_id
        Swal.fire(this.$t('messages.generated'), '', 'success')
        this.showSpinner = false
      })
    },
  },

  computed: {
    ...mapState({
      savedWorksList: (state) => state.TitleList.worksLists,
      treatmentsList: (state) => state.TitleList.treatmentsList,
    }),
    ...mapGetters(['treatmentsSummary', 'approvedYearsInHSList', 'roadsInTreatmentsList']),
    roadsList() {
      return this.roadsInTreatmentsList(this.selecteRegion)
    },
    selectedYear() {
      return !this.selectedWorklist ? null : Number(this.savedWorksList.find(el => el.work_list_id == this.selectedWorklist).year)
    },
    footerTable() {
      return [
        this.$t('label.total'),
        `${this.$t('budget.works_count')}: ${numFormat(this.treatmentsSummary.totalRecords, 0)}`,
        `${this.$t('budget.total_cost')}: ${numFormat(this.treatmentsSummary.totalSum / this.showAmounts, 2)}`,
      ]
    },
    tableData() {
      return this.selectedRoad ? this.treatmentsList.filter((item) => {
        return item.fk_road == this.selectedRoad
      }) : this.treatmentsList
    }

  },
  watch: {
  }
}
</script>
<style lang="scss" scoped>
.md-card {
  margin: 0px 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}
</style>